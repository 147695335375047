.selectedSolutions {
  display: flex;
  gap: 20px;
  margin-top: 20px;
  flex-wrap: wrap;

  &_map {
    display: flex;
    gap: 10px;
    padding: 10px;
    border: 1px solid #e1e8ee;
  }
}
